import {ethers} from "ethers";
import {network, provider} from "../network";

const minterABI = require("./Minter.json");

const minter = new ethers.Contract(network.minter, minterABI, provider);

export const fetchPrice = () => minter.price().then(bn => ethers.utils.formatEther(bn));

export const fetchMintActive = () => minter.mintActive();

export const mint = (signer, account, price, amount) => {
    console.log({mint: {signer, account, price, amount}})
    return minter.connect(signer).mint(amount, account, 0, 0, ethers.constants.AddressZero, {value: price.mul(amount)});
}