import React from 'react';
import Collapsible from 'react-collapsible';

const Faq = () => {
  return (
    <div>
      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="50">
      <Collapsible
        transitionTime={400}
        trigger="How did Oddblox come into existence?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
        The Oddblox NFT project grew from the team having a deep appreciation for art and the rise of digital assets.
        Binance Smart Chain offers other NFT projects, but none were creatively driven projects with a focus on the art. 
        There was tremendous opportunity to bring art to an ecosystem that was more accessible than other chains.
        </p>
      </Collapsible>
      </div>
      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100">
      <Collapsible
        transitionTime={400}
        trigger="How much does it cost to mint?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
        Each Oddblox NFT will cost 0.25 BNB to mint. 
        </p>
      </Collapsible>
      </div>

      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="150">
      <Collapsible
        transitionTime={400}
        trigger="How many will be withheld from mint?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
          36 will be withheld to be used for future giveaways and marketing.
        </p>
      </Collapsible>
      </div>
      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
      <Collapsible
        transitionTime={400}
        trigger="What makes Oddblox different?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
        Many projects implement a steep bonding curve, promises of floor sweeps, unobtainable roadmap goals and manufactured hype.
        Our goal from the start was to bring art to a new space and make it accessible, all the while building an organic community aligned with our principals.
        Our road map goals have been planned and the cost structure of minting was designed with funding the future in mind. 
        </p>
      </Collapsible>
      </div>
      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="250">
      <Collapsible
        transitionTime={400}
        trigger="Will my NFT be 3D?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
        We showcase the Oddblox NFTs in a 3D rendered format to show future use cases.
        The NFT you see in your wallet will be a flat 2D version.
        This is because the image and metadata lives in the token in a format vastly superior to typical raster formats.
        We will be implementing a means to frame your Oddblox NFT so you can show it off, but retain the pure original.
        </p>
      </Collapsible>
      </div>
      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="300">
      <Collapsible
        transitionTime={400}
        trigger="Why Binance Smart Chain?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
        Binance offers fast and low-cost transactions meaning more people have the ability to own a unique part of the culture without high gas costs of other networks.
        The on-chain generation process is computationally expensive and the gas cost to facilitate minting on other chains would be costly.
        The decision to build on the BSC became clear.
        </p>
      </Collapsible>
      </div>
      <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="350">
      <Collapsible
        transitionTime={400}
        trigger="Smart contract?"
        easing={'cubic-bezier(0.165, 0.840, 0.440, 1.000)'}
      >
        <p>
        The Oddblox are created based on the Binance Smart Chain contract. <a href="https://bscscan.com/address/0xa1FaCbeE77125C4FD63ebeaaed43926C3338Ea04" alt="Oddblox Smart Contract">You can locate the smart contract here.</a>
        </p>
      </Collapsible>
      </div>
    </div>
  );
};

export default Faq;