import React from "react";
import Social from "../Social";
import TextLoop from "react-text-loop";
import MintModal from "../modal/Modal"

const SliderParallax = () => {
  return (
    <>
        <div className="ob_tm_hero" id="home">
          <div className="background">
            <div
              className="image"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "img/slider/NUATTEMPT_ANIMATED_OPTIMIZED2.svg"
                })`,
              }}
            ></div>
          </div>
          {/* End bg */}
          <div className="container">
            <div className="content">
              <div className="content_inner">
                <h1
                  className="job"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="50"
                >
                <TextLoop springConfig={{
                  stiffness: 340,
                  damping: 36,
                  interval: 600,
                  fade: false,
                  }}>
                <div>Experimental art</div>
                <div>Generative forms</div>
                <div>Modern design</div>    
                </TextLoop>
                <br></br>
                purely on-chain.
                </h1>
                <h2
                  className="typer"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                Each piece forever lives in an infinitely scalable form on the Binance Smart Chain.
                </h2>
                <div
                  className="mintwrapper"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="150">
                    <MintModal/>
                </div>
              </div>
            </div>
          </div>
          <div className="social">
            <Social />
          </div>
        </div>
    </>
  );
};

export default SliderParallax;
