import { useContext, useState, useEffect } from "react";
import { TotalSupplyContext } from "../../App";
import { fetchTokenByIndex } from "../../contracts/oddblox";
import HomeGallery from "./HomeGallery";

const RecentGallery = (props) => {
    const totalSupply = useContext(TotalSupplyContext);
    
    let [tokenIds, setTokenIds] = useState([]);

    useEffect(() => {
        const elements = totalSupply >= 6 ? 6 : totalSupply;
        const tokenIndices = Array.from({length: elements}, (_, i) => totalSupply-i-1);
    
        Promise.all(tokenIndices.map(i => fetchTokenByIndex(i)))
            .then(
                _tokenIds => setTokenIds(_tokenIds)
            )
    }, [totalSupply]);

    return (
        <HomeGallery tokenIds={tokenIds}></HomeGallery>
    );
};

export default RecentGallery;