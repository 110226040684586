export const GalleryItem = (props) => {

  const imageUrl = props?.imageUrl;
  const label = props?.label;
  
  return (
    <>
      <li data-aos="fade-right" data-aos-duration="1200">
        <div className="list_inner">
          <div className="title">
            <span>{label}</span>
          </div>
          
          <a href={imageUrl}>
            <img src={imageUrl} alt={label} />
          </a>
          
        </div>
      </li>
    </>
  );
};
