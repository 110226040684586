import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    autoplay: true,
    lazyLoad: 'ondemand',
    dots: true,
    arrow: false,
    infinite: true,
    fade: true,
    cssEase: 'linear',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1040,
          settings: {
            slidesToShow: 1,
            dots: false,
          },
        },
      ],
  
  };

  return (
    <ul>
      <Slider {...settings}>
        <li>
          <img src="img/faq/GalleryView.png" alt="Metaverse Gallery View No. 01"/>
        </li>
        <li>
        <img src="img/faq/GalleryView2.png" alt="Metaverse Gallery View No. 02"/>
        </li>
        <li>
        <img src="img/faq/GalleryView3.png" alt="Metaverse Gallery View No. 03"/>
        </li>
      </Slider>
    </ul>
  );
}
