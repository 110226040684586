import { SiBinance } from "react-icons/si";
import {TotalSupplyContext, PriceContext} from "../../App"
import {useContext} from "react"

const MintInfo = () => {
  let totalSupply = useContext(TotalSupplyContext);
  let price = useContext(PriceContext);

  return (
    <h3 className="mint_h3">
      {totalSupply ? 4096-totalSupply : "?"}/4096 available at{" "}
      {price ?? "?"}
      <SiBinance /> BNB each.
    </h3>
  );
};

export default MintInfo;
