import { useEffect, useState } from "react";
import { hooks } from "../../connectors/priority";
import { fetchTokensOfOwner } from "../../contracts/oddblox";
import HomeGallery from "./HomeGallery";

const { usePriorityAccounts } = hooks;

const MyGallery = (props) => {
  const [tokenIds, setTokenIds] = useState([]);
  const accounts = usePriorityAccounts();

  useEffect(() => {
    if (!accounts) {
      setTokenIds([]);
    } else {
      setTokenIds(null);
      fetchTokensOfOwner(accounts[0]).then(setTokenIds);
    }
  }, [accounts]);

  return tokenIds != null ? (
    <HomeGallery tokenIds={tokenIds}></HomeGallery>
  ) : (
    <div className="ob_tm_portfolio portfolio_list"><p>Loading...</p></div>
  );
};

export default MyGallery;
