import React from "react";
import {
  FaTwitter,
  FaDiscord,
  FaMediumM,
  FaTelegramPlane,
} from "react-icons/fa";

const SocialShare = [
  {
    iconName: <FaTwitter />,
    link: "https://twitter.com/oddbloxNFT",
  },
  { iconName: <FaDiscord />,
    link: "http://discord.gg/RDEMmWUf5Q"
  },
  { iconName: <FaTelegramPlane />,
    link: "http://t.me/oddblox"
  },
  { iconName: <FaMediumM />,
    link: "https://oddblox.medium.com/" 
  },
];

const Social = () => {
  return (
    <ul>
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            {val.iconName}
          </a>
        </li>
      ))}
        <li>
          <a href="https://nftkey.app/collections/oddblox/" target="_blank" rel="noreferrer"><img src="/img/svg/nftkey.svg" alt="NFTKey Marketplace" /></a>
        </li>
        <li>
          <a href="https://bscscan.com/address/0xa1FaCbeE77125C4FD63ebeaaed43926C3338Ea04" target="_blank" rel="noreferrer"><img src="/img/svg/bscscan.svg" alt="Oddblox Smart Contract" /></a>
        </li>

    </ul>
  );
};

export default Social;
