import { ethers } from "ethers";

const mainnet = true;

const networkTestnet = {
    minter: "0xb5fdcd17179e962fc078e80cee4ab9ae70bb4a23",
    oddblox: "0xb90e2d000ae4d59e43a2444e3088810c39c0d6fc",
    chainId: 97,
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    price: ethers.utils.parseEther("0.25")
}

const networkMainnet = {
    minter: "0xC6aBc59b66394BDa71ed5b4C647D342156a09273",
    oddblox: "0xa1FaCbeE77125C4FD63ebeaaed43926C3338Ea04",
    chainId: 56,
    rpc: "https://bsc-dataseed1.defibit.io/",
    price: "0.25"
}

export const network = mainnet ? networkMainnet : networkTestnet;

export const provider = new ethers.providers.JsonRpcProvider(network.rpc);