import React, { useState, useContext } from "react";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { MdClose } from "react-icons/md";
import { metaMask } from "../../connectors/metaMask";
import { walletConnect } from "../../connectors/walletConnect";
import { network } from "../../network";
import { hooks } from "../../connectors/priority";
import MintInfo from "./MintInfo";
import toast from "react-hot-toast";
import { PriceContext, MintActiveContext } from "../../App";
import { mint } from "../../contracts/minter";
import { ethers } from "ethers";

Modal.setAppElement("#root");

const {
  usePriorityAccounts,
  //usePriorityError,
  usePriorityIsActivating,
  usePriorityIsActive,
  usePriorityProvider,
} = hooks;

export default function App() {
  const mintActive = useContext(MintActiveContext);
  const price = useContext(PriceContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMintAmount, setSelectedMintAmount] = useState(1);
  //const [afterMintMessage, setAfterMintMessage] = useState("");
  const [duringMintMessage, setDuringMintMessage] = useState("Mint");

  const isActivating = usePriorityIsActivating();
  const isActive = usePriorityIsActive();
  const activate = () =>
    window.ethereum
      ? metaMask.activate(network.chainId)
      : walletConnect.activate(network.chainId);
  const accounts = usePriorityAccounts();
  //const error = usePriorityError();
  const provider = usePriorityProvider();

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const connectOrToggleModal = () => {
    if (isActive) {
      toggleModal();
    } else {
      activate();
    }
  };

  const initiateMint = () => {
    setDuringMintMessage(
      <div className="mint_loader">
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
      </div>
    );

    mint(
      provider.getSigner(),
      accounts[0],
      ethers.utils.parseEther(price),
      selectedMintAmount
    )
      .then((res) => {
        toast.success(`You successfully minted ${selectedMintAmount} Oddblox!`);
        toggleModal();
        setDuringMintMessage("Mint");
      })
      .catch((err) => {
        console.log("ERROR:", err);
        if (
          err === "execution reverted: Sale not started yet." ||
          err?.data?.message === "execution reverted: Sale not started yet."
        ) {
          toast.error("Sale not started yet!");
        } else {
          toast.error(
            "Error while minting. Make sure you are connected and have sufficient funds."
          );
        }
        toggleModal();
        setDuringMintMessage("Mint");
      });
  };

  const modalOptions = [1, 2, 3, 4, 5];

  const displayModalOptions = modalOptions.map((option) => {
    return <option key={option}>{option}</option>;
  });

  return (
    <div>
      <div className="mintmodal">
        <button
          className="mint-calltoaction"
          data-tip
          data-for="Mint"
          disabled={mintActive !== true}
          onClick={connectOrToggleModal}
        >
          {!isActive ? (
            <ReactTooltip
              id="Mint"
              className="ToolTipText"
              place="bottom"
              effect="solid"
            >
              <p>Connect your wallet to mint!</p>
            </ReactTooltip>
          ) : null}

          {mintActive == null ? (
            <>Loading...</>
          ) : mintActive !== true ? (
            <>Mint Soon!</>
          ) : !isActivating ? (
            <>Mint Now!</>
          ) : (
            <>Connecting...</>
          )}
        </button>

        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          className="custom-modal-2"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div className="box_inner_2">
            <div className="inner">
            <div className="closebox"
                onClick={() => {toggleModal();}}
            >
              <MdClose />
              </div>
              <h1 className="mint_h1"> Let's mint some blox! </h1>
              <p className="mint_p">
                Make sure your on the Binance Smart Chain and your wallet is
                connected.
                <br></br>
              </p>
              <MintInfo></MintInfo>
              <div className="selection-wrapper">
                <select
                  className="mint-amount"
                  name="mintamount"
                  value={selectedMintAmount}
                  key={selectedMintAmount}
                  onChange={(e) => {
                    setSelectedMintAmount(e.target.value);
                  }}
                >
                  {displayModalOptions}
                </select>
              </div>
              <div className="btn-box">
                <button
                  className="mintmodule-btn"
                  onClick={() => {
                    initiateMint();
                  }}
                >
                  {duringMintMessage}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
