import React, {useState, useContext} from "react";
import Header from "../components/header/Header";
import Slider from "../components/hero/SliderParallax";
import About from "../components/about/AboutParallax";
import RecentGallery from "../components/gallery/RecentGallery";
import MyGallery from "../components/gallery/MyGallery";
import Footer from "../components/footer/Footer";
import SocialBottom from "../components/SocialBottom";
import Roadmap from "../components/roadmap/Roadmap";
import Faq from "../components/faq/Faq";
import {FaTwitter} from "react-icons/fa";
import FaqSlider from "../components/faq/faq_slider";
import Partners from "../components/partners/Partners";
import { hooks } from "../connectors/priority";
import { MintActiveContext } from "../App";
import PlaceholderGallery from "../components/gallery/PlaceholderGallery";

const {
  usePriorityAccounts,
} = hooks;

const Home = () => {
  document.body.classList.add("light");

  const mintActive = useContext(MintActiveContext);
  const [myGallery, setMyGallery] = useState(false);
  const accounts = usePriorityAccounts();

  const toggleMyGallery = () => setMyGallery(!myGallery);

  return (

    <div className="home-light">

      <Header />
      {/* End Header */}

      <Slider />
      {/* End Slider */}

      <div className="ob_tm_about" id="about" data-to-scrollspy-id="about">
        <div className="container">
          <div className="ob_tm_title_holder">
            <span>01</span>
            <h2>About</h2>
            <p>
            Through use of code and compositional elements, true generative art has arrived on the BNB Chain. Each piece abstracts negative space in a unique way leaving the viewer open to interpret the forms created.
            </p>
          </div>
          {/* TITLE END */}
          <About />
        </div>
      </div>
      {/* /ABOUT */}

      {/* SERVICES */}
      <div className="ob_tm_services" id="roadmap">
        <div className="container">
          <div className="ob_tm_title_holder">
            <span>02</span>
            <h2>Roadmap v1</h2>
            <p>
              See what we are up to and what we have planned for the future of Oddblox. The roadmap will be continuously updated through various stages of the minting process.
            </p>
          </div>
          {/* TITLE END */}
          <div className="inner" data-aos-anchor-placement="top-center" data-aos="fade-up" data-aos-duration="1200" >
            <Roadmap />
            </div>
        </div>
      </div>
      {/* /SERVICES */}

      {/* PORTFOLIO */}
      <div className="ob_tm_portfolio" id="gallery">
        <div className="container">
          <div className="ob_tm_title_holder">
            <span>03</span>
            <h2>{myGallery ? "My Gallery" : "Gallery"}</h2>
            <p>
              {myGallery ? "Check out all of the Oddblox in your wallet below." : "Check out the most recent Oddblox that have been minted below."}
            </p>
            {(accounts != null && mintActive) ? <button onClick={toggleMyGallery}>{myGallery ? "View Freshly Minted" : "View My Gallery"}</button> : <></>}
          </div>
          {/* TITLE END */}
          {myGallery ? <MyGallery/> : (mintActive ? <RecentGallery /> : <PlaceholderGallery />)}
        </div>
      </div>
      {/* /PORTFOLIO */}

      {/* TEAM */}
      <div className="ob_tm_team" id="team">
        <div className="container">
          <div className="ob_tm_title_holder">
            <span>04</span>
            <h2>Meet the Team</h2>
            <p>
            The Oddblox series began from two friends seeing a gap in the NFT market on the Binance Smart Chain and the opportunity to bring generative on-chain art to a new space.
            </p>
          </div>
          <div className="ob_tm_resume">
              <div className="row">
                <ul>
                  <li>
                  <div className="list_inner" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="">
                  <div className="team_member">
                  <img src="/img/team/SVG_CB.svg" alt="Team Member CB Illustration" />                  
                  </div>
                      <div className="teambox">
                        <h3>CB</h3>
                          <a href="https://twitter.com/BachtellDesign"><FaTwitter/></a>
                          </div>
                      <p className="team_desc">Designer + Front-End</p>
                  </div>
                </li>
                
                <li>
                  <div className="list_inner" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                  <div className="team_member">
                  <img src="/img/team/SVG_KD.svg" alt="Team Member KD Illustration" />                  
                  </div>
                    <div className="teambox">
                      <h3>KD</h3>
                        <a href="https://twitter.com/KDOBNFT"><FaTwitter/></a>
                    </div>
                  <p className="team_desc">Code + Development</p>
                  </div>
                 </li>
                 </ul>
               </div>
           </div>
        </div>
      </div>
      {/* /TEAM */}

      {/* /PARTNERS */}
      <div className="ob_tm_testimonials" id="partners">
        <div className="container">
          <div className="ob_tm_title_holder">
            <span>05</span>
            <h2>Partners</h2>
            <p>
              Without the help of their services our collection wouldn't be possible.
            </p>
          </div>
          <div className="partner_padding">
                <Partners/>
              </div>
        </div>
      </div>
      {/* /PARTNERS */}

      {/* FAQ */}
      <div className="ob_tm_news"id="faq">
        <div className="container">
          <div className="ob_tm_title_holder">
            <span>06</span>
            <h2>F.A.Q</h2>                
            <p>
            Below are a few common questions from those in our community.
            </p>
            </div>
            <div className="faq_inner">
              <div className="faq_left" data-aos="fade-right" data-aos-duration="1200">
              <Faq/>
              </div>

              <div className="faq_right" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200">
              <FaqSlider/>
              </div>

          </div>
        </div>
      </div>
      {/* /FAQ */}

      {/*  CONTACT */}
      <div className="ob_tm_contact" id="community">
        <div className="container">
          <div className="contact_inner">
            <div
              className="left"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="ob_tm_title_holder">
                <span>Community</span>
                <h2>Join us as we bring true art to the BNB Chain!</h2>
              </div>
            </div>
            {/* End .left */}

            <div
              className="right"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
             <div className="short_list"><SocialBottom /></div>
            </div>
          </div>
        </div>
      </div>
      {/* /CONTACT */}

      {/* COPYRIGHT */}
      <div className="ob_tm_copyright">
        <div className="container">
          <Footer />
        </div>
      </div>
      {/* /COPYRIGHT */}
    </div>
  );
};

export default Home;
