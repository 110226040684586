import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

function RoadMap() {
  return (
<VerticalTimeline >
  <VerticalTimelineElement 
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#f9f9fc', color: '#6E6E6E' }}
    iconStyle={{ background: '#FCFCFC', color: '#000' }}
    icon={(<>0%</>)}
  >
    <h3>Launch</h3>
    <p>
    The Oddblox series mint starts.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#f9f9fc', color: '#6E6E6E' }}
    iconStyle={{ background: '#FCFCFC', color: '#000' }}
    icon={(<>10%</>)}
  >
    <h3>Awareness</h3>
    <p>
    Following the start of mint, we will begin targeted marketing campaigns to increase exposure.
    This will allow early adopters to acquire pieces prior to widespread attention.
    <br></br>
    <br></br>
    We will also offer digital + physical giveaway items throughout the various phases of the mint.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#f9f9fc', color: '#6E6E6E' }}
    iconStyle={{ background: '#FCFCFC', color: '#000' }}
    icon={(<>25%</>)}
    
  >
    <h3>Growth</h3>
    <p>
    We will establish necessary partnerships to allow holders to list/acquire other Oddblox NFTs through a trusted marketplace.
    <br></br>
    <br></br>
    Production on our "OddBox" will begin. This is a limited-run item that will include digital frames in numbered and hologram boxes along with other extras.
    Oddblox is more than an  NFT project, we are forging a brand.
    Our physical goods surrounding the project will further support this notion.
    <br></br>
    <br></br>
    A select few holders, team members, and staff will be given these reserved promotional items.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#f9f9fc', color: '#6E6E6E' }}
    iconStyle={{ background: '#FCFCFC', color: '#000' }}
    icon={(<>50%</>)}
  >
    <h3>Community</h3>
    <p>
    Our core two focuses from inception are: people and providing accessible art.  
    <br></br>
    <br></br>
    This will be emphasized by depositing into a community wallet. Funding from that wallet will be used to give back to supporters and fund the future of the Oddblox platform.
    Have an idea? Want to work with us? You will have the opportunity to do so.
    Grassroots funding and growth is the future as we see it.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#f9f9fc', color: '#6E6E6E' }}
    iconStyle={{ background: '#FCFCFC', color: '#000' }}
    icon={(<>100%</>)}
  >
    <h3>Future</h3>
    <p>
    Integration with existing platforms and building our larger platform will be our focus looking forward.
    <br></br>
    <br></br>
    We will focus on a platform for showcasing your collection and use of your NFTs in digital worlds. Listing Oddblox on PancakeSwap's NFT Marketplace will open more opportunities for Oddblox holders. 
    <br></br>
    <br></br>
    The new roadmap will be updated after our genesis collection is fully minted. Further details on our gallery platform will be provided as well. 
    </p>
  </VerticalTimelineElement>
</VerticalTimeline>
  );
};

export default RoadMap;
