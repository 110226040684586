import React, { useEffect, createContext, useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedCursor from "react-animated-cursor";
import { Toaster, toast } from "react-hot-toast";
import Home from "./views/Home";
import { fetchTotalSupply } from "./contracts/oddblox";
import { fetchPrice, fetchMintActive } from "./contracts/minter";

export const TotalSupplyContext = createContext(null);
export const PriceContext = createContext(null);
export const MintActiveContext = createContext(null);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function App() {
  const [totalSupply, setTotalSupply] = useState(null);
  const [price, setPrice] = useState(null);
  const [mintActive, setMintActive] = useState(null);
  const prevMintActive = usePrevious(mintActive);

  useEffect(() => {
    fetchTotalSupply().then(setTotalSupply);
    fetchPrice().then(setPrice);
    fetchMintActive().then(setMintActive);

    const timer = setInterval(() => {
      fetchTotalSupply().then(setTotalSupply);
      fetchPrice().then(setPrice);
      fetchMintActive().then(setMintActive);
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    console.log("totalSupply", totalSupply);
  }, [totalSupply]);

  useEffect(() => {
    //console.log("price", price);
  }, [price]);

  useEffect(() => {
    //console.log("mintActive", mintActive);
    if (prevMintActive === false && mintActive === true) {
      toast.success(`Minting started!`);
    }
  }, [mintActive, prevMintActive]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <MintActiveContext.Provider value={mintActive}>
      <PriceContext.Provider value={price}>
        <TotalSupplyContext.Provider value={totalSupply}>
          <div className="ob_tm_all_wrap">
            <ScrollToTop />
            <Toaster
              position="bottom-center"
              toastOptions={{
                className: "notification",
                duration: 4000,
                dismissible: true,
                style: {
                  background: "rgb(55,55,55,0.9)",
                  color: "#fff",
                },
              }}
            />
            <Router>
              <Link to="/"></Link>
              <Switch>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Router>

            <AnimatedCursor
              innerSize={8}
              outerSize={32}
              color="33, 33, 33"
              outerAlpha={0.25}
              innerScale={0.75}
              outerScale={1.25}
            />
          </div>
        </TotalSupplyContext.Provider>
      </PriceContext.Provider>
    </MintActiveContext.Provider>
  );
}

export default App;
