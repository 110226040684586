import React from "react";
import AboutSlider from "../about/About_slider";

const AboutParallax = () => {
  return (
    <>
      <div className="about_inner">
        <div className="left"
        data-aos="fade-right"
        data-aos-duration="1200"
        data-aos-delay="50">
          <AboutSlider/>
        </div>
        <div
          className="right"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="short">
            <h3>A series of 4096 abstract + expressive on-chain NFTs. </h3>
            <p>
            Using a variety of shapes and color palettes each Oddblox NFT is assembled on mint. 
            The created image is then encoded and stored in the token.
            When you own the token you truly own the art.
            This allows the greatest flexibility for future use cases of the Oddblox NFT genesis series.
            <br></br>
            <br></br>
            The generated NFTs serve as a digital Rorschach test with the viewer projecting their interpretation of the forms. 
            Each piece is unique and possesses traits, though these traits do not directly determine visual appeal and rarity is verifiably random.
            Oddblox launches Feb 14th @ 5 PM UTC at the price of 0.25BNB per mint.
            </p>
          </div>

        </div>
      </div>
    </>
  );
};

export default AboutParallax;
