import {ethers} from "ethers";
import {network, provider} from "../network";

const oddbloxABI = require("./Oddblox.json");
const oddblox = new ethers.Contract(network.oddblox, oddbloxABI, provider);

export const fetchTotalSupply = () => oddblox.totalSupply().then(bn => +bn);

export const fetchTokenByIndex = (index) => oddblox.tokenByIndex(index).then(bn => +bn);

export const fetchTokensOfOwner = (user) => oddblox.tokensOfOwner(user).then(bns => bns.map(bn => +bn));