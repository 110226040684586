import React from "react";
import {
  FaTwitter,
  FaDiscord,
  FaMediumM,
  FaTelegramPlane,
} from "react-icons/fa";


const SocialShare = [
  {
    iconName: <FaTwitter />,
    link: "https://twitter.com/oddbloxNFT",
  },
  { iconName: <FaDiscord />,
    link: "http://discord.gg/RDEMmWUf5Q"
  },
  { iconName: <FaTelegramPlane />,
    link: "http://t.me/oddblox"
  },

  { iconName: <FaMediumM />,
    link: "https://oddblox.medium.com/" 
  },
];
const SocialBottom = () => {
  return (
    <ul>
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            {val.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialBottom;
