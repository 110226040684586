import {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import {ethers} from "ethers";
import { SiBinance } from "react-icons/si";

import { network } from "../network"
import { metaMask } from "../connectors/metaMask";
import { walletConnect } from "../connectors/walletConnect";
import { hooks } from "../connectors/priority";

const {
  usePriorityAccounts,
  usePriorityError,
  usePriorityIsActivating,
  usePriorityIsActive,
  usePriorityProvider,
} = hooks;

const shortAddress = (address) => address.slice(0, 4) + "..." + address.slice(-4);

function useBalances(provider, accounts) {
  const [balances, setBalances] = useState(undefined)

  useEffect(() => {
    if (provider && accounts?.length) {
      let stale = false

      void Promise.all(accounts.map((account) => provider.getBalance(account))).then((balances) => {
        if (!stale) {
          setBalances(balances)
        }
      })

      return () => {
        stale = true
        setBalances(undefined)
      }
    }
  }, [provider, accounts])

  return balances
}

const ConnectButton = () => {
  const isActivating = usePriorityIsActivating();
  const isActive = usePriorityIsActive();
  const activate = () => window.ethereum ?  metaMask.activate(network.chainId) : walletConnect.activate(network.chainId);
  const deactivate = () => window.ethereum ?  metaMask.deactivate() : walletConnect.deactivate();
  const accounts = usePriorityAccounts();
  const error = usePriorityError();
  const provider = usePriorityProvider();

  const balances = useBalances(provider, accounts);

  if (error) {
    console.log("error", error)
  }

  return (
    <div>
      <button
        className="connect-btn"
        data-tip
        data-for="Wallet"
        onClick={!isActive ? activate : deactivate}
      >
        <ReactTooltip
          id="Wallet"
          className="ToolTipText"
          place="bottom"
          effect="solid"
        >
          {isActive ? (
            balances ? (
              <p>Balance: {(+ethers.utils.formatEther(balances[0])).toFixed(2)} <SiBinance /> BNB</p>
            ) :
            (
              <p>Loading balance...</p>
            )
        ) : isActivating ? (
          <>
          <p>Connecting...</p>
          </>
        ) : (<>
          <p>Connect your wallet!</p>
        </>
        )}
        </ReactTooltip>
        {isActive ? (
          <>
            <div className="status-icon-on"></div>
            {shortAddress(accounts[0])}
          </>
        ) : isActivating ? (
          <>
            <div className="status-icon-on"></div>
            Connecting
          </>
        ) : (<>
          <div className="status-icon-off"></div>
          Disconnected
        </>
        )}
      </button>
    </div>
  );
};
export default ConnectButton;
