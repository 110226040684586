import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    autoplay: true,
    pauseOnHover: false,
    dots: false,
    arrow: false,
    infinite: true,
    fade: true,
    cssEase: 'linear',
    autoplaySpeed: 1400,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,  
  };

  return (
    <ul>
      <Slider {...settings}>
        <li>
          <img src="/img/about/L-01.png" alt="Oddblox NFTs Slide No. 01"/>
        </li>
        <li>
        <img src="/img/about/L-02.png" alt="Oddblox NFTs Slide No. 02"/>
        </li>
        <li>
        <img src="/img/about/L-03.png" alt="Oddblox NFTs Slide No. 03"/>
        </li>
        <li>
        <img src="/img/about/L-04.png" alt="Oddblox NFTs Slide No. 04"/>
        </li>
      </Slider>
    </ul>
  );
}
