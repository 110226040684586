import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import {GalleryItem} from "./GalleryItem"

const HomeGallery = (props) => {
  const tokenIds = props?.tokenIds;
  const label = props?.label ?? ((tokenId) => `#${tokenId}`);
  const imageUrl = props?.imageUrl ?? ((tokenId) => `https://api.oddblox.io/api/image/${tokenId}`)

  return (
    <SimpleReactLightbox>
      <div className="portfolio_list">
        <ul className="gallery_zoom">
          <SRLWrapper>
            {tokenIds.map(tokenId => <GalleryItem key={tokenId} label={label(tokenId)} imageUrl={imageUrl(tokenId)}></GalleryItem>)}
          </SRLWrapper>
        </ul>
      </div>
    </SimpleReactLightbox>
  );
};

export default HomeGallery;
