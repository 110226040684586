import HomeGallery from "./HomeGallery";

const RecentGallery = (props) => {
    const tokenIds = [1,2,3,4,5,6];
    const imageUrl = (tokenId) => `img/portfolio/${tokenId}.png`

    return (
        <HomeGallery tokenIds={tokenIds} imageUrl={imageUrl}></HomeGallery>
    );
};

export default RecentGallery;