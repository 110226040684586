import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import logo from "../header/oddblox_dark.svg";
import WalletConnect from "../WalletConnect";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "ob_tm_topbar animate" : "ob_tm_topbar"}>
        <div className="in">
          <div className="topbar_inner">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Oddblox logo"/>
              </Link>
            </div>
            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={[
                  "about",
                  "roadmap",
                  "gallery",
                  "team",
                  "faq",
                  "community",
                ]}
                currentClassName="current"
                offset={-88}
              >
                <li className="current">
                  <a href="#about">
                    <span className="first">About</span>
                    <span className="second">About</span>
                  </a>
                </li>
                <li>
                  <a href="#roadmap">
                    <span className="first">Roadmap</span>
                    <span className="second">Roadmap</span>
                  </a>
                </li>
                <li>
                  <a href="#gallery">
                    <span className="first">Gallery</span>
                    <span className="second">Gallery</span>
                  </a>
                </li>
                <li>
                  <a href="#team">
                    <span className="first">Team</span>
                    <span className="second">Team</span>
                  </a>
                </li>
                <li>
                  <a href="#partners">
                    <span className="first">Partners</span>
                    <span className="second">Partners</span>
                  </a>
                </li>
                <li>
                  <a href="#faq">
                    <span className="first">FAQ</span>
                    <span className="second">FAQ</span>
                  </a>
                </li>
                <li>
                  <a href="#community">
                    <span className="first">Community</span>
                    <span className="second">Community</span>
                  </a>
                </li>
                <li>
                <WalletConnect/>
                </li>
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>
      {/* /TOPBAR */}

      <div className="mobile-menu-wrapper">
          <Scrollspy
            className="mobile_menu-icon"
            items={[
            "about",
            "roadmap",
            "team",
            "gallery",
            "faq",
            "community",
            ]}
            currentClassName="current"
            offset={-65}
          >
            <div className="mobile-inner">
              <nav className="navBar">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="oddblox logo"/>
                  </Link>
                </div>
                <button className="navBar-button" onClick={handleToggle}>
                {navbarOpen ? (
                  <MdClose style={{ color: "#082D34", width: "32px", height: "32px" }} />
                ) : (
                  <FiMenu style={{ color: "#082D34", width: "32px", height: "32px" }} />
                )}
                </button>
      
                <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>

                <li>
                  <a href="#about">
                    <span>About</span>
                  </a>
                </li>
                <li>
                  <a href="#roadmap">
                    <span>Roadmap</span>
                  </a>
                </li>
                <li>
                  <a href="#gallery">
                  <span>Gallery</span>
                  </a>
                </li>

                <li>
                  <a href="#team">

                    <span>Team</span>
                  </a>
                </li>
                <li>
                  <a href="#partners">
                    <span>Partners</span>
                  </a>
                </li>

                <li>
                  <a href="#faq">
                    <span>FAQ</span>
                  </a>
                </li>
                <li>
                  <a href="#community">
                    <span>Community</span>
                  </a>
                </li>
                <li>
                <WalletConnect/>
                </li>
                </ul>
              </nav>
            </div>
          </Scrollspy>
        </div>
      {/* End mobile-menu */}
    </>
  );
};

export default Header;
