import { initializeConnector } from '@web3-react/core'
import { WalletConnect } from '@web3-react/walletconnect'
import { network } from '../network'

export const [walletConnect, hooksWalletConnect] = initializeConnector(
  (actions) =>
    new WalletConnect(actions, {
      rpc: {
          1: "https://cloudflare-eth.com",
          56: "https://bsc-dataseed.binance.org/",
          97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
      },
      chainId: network.chainId
    }, true),
  [56, 97]
)