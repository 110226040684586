import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    autoplay: true,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  return (
    <ul>
      <Slider {...settings}>
        <li className="item">
        <a href="https://chain.link/"><img src="/img/partners/PartnerBW1.svg" alt="BNB Smart Chain" /></a>
        </li>
        <li className="item">
        <a href="https://www.bnbchain.world/en/smartchain"><img src="/img/partners/PartnerBW2.svg" alt="Chainlink" /></a>
        </li>
        <li className="item">
        <a href="https://nftkey.app/collections/oddblox/"><img src="/img/partners/PartnerBW3.svg" alt="NFTKey" /></a>
        </li>
        <li className="item">
        <a href="https://www.rareboard.com/oddblox"><img src="/img/partners/PartnerBW4.svg" alt="Rareboard" /></a>
        </li>
      </Slider>
    </ul>
  );
}
