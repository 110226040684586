import React from "react";
import { FaHeart } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="inner">
        <div className="copy">
          <p>
          <b>Made with love.</b><FaHeart/> |
            &copy; {new Date().getFullYear()} by{" "}
            <a
              href="https://oddblox.io"
              target="_blank"
              rel="noreferrer"
            >
              <b>Oddblox LLC</b>
            </a>
            . All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
